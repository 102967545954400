@import "../styles/mixins.css";
.orders_table {
  max-width: 1050px;
  border-collapse: collapse;
  padding: 8px;
}
.orders_table td {
  border: 1px solid rgba(150, 163, 174, 0.1);
  height: 36px;
  text-align: center;
  line-height: 32px;
  padding: 4px;
  box-sizing: border-box;
}
.orders_table th {
  background: rgba(150, 163, 174, 0.1);
  height: 38px;
  font-weight: 400;
  font-size: 14px;
  color: #96A3AE;
}
.orders_table th:first-of-type {
  border-radius: 8px 0px 0px 0px;
}
.orders_table th:last-of-type {
  border-radius: 0px 8px 0px 0px;
}
.orders_table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.orders_table tr:hover {
  background-color: #ddd;
}

.payments_pagination_selector {
  position: relative;
  width: 50px;
  height: 28px;
  display: flex;
  align-items: center;
  border: 2px solid rgba(150, 163, 174, 0.1);
  border-radius: 4px;
  margin-top: 12px;
}
.payments_pagination_selector_ico {
  position: absolute;
  right: 2px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
  rotate: 90deg;
}
.payments_pagination_selector select {
  z-index: 5;
  padding-left: 12px;
  border: none;
  outline: none;
  background: none;
  width: 50px;
}/*# sourceMappingURL=payments.css.map */