.files_list {
  padding-top: 32px;
  padding-bottom: 80px;
}
.files_list h2 {
  margin-bottom: 24px;
}
.files_list .btn_reload {
  margin-bottom: 24px;
}
.files_list table td {
  border: 1px solid rgba(0, 0, 0, 0.1450980392);
  padding: 15px;
  font-size: 12px;
  line-height: 19px;
}
.files_list table td.thumbnail {
  width: 120px;
  text-align: center;
}
.files_list table td.thumbnail img {
  display: inline-block;
  width: 120px;
}/*# sourceMappingURL=index.css.map */