.lesson_sidebar {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  min-height: 100vh;
  width: 360px;
  border-right: 1px solid #E8E8E8;
  overflow: auto;
  background-color: #fff;
}
.lesson_sidebar .sidebar_tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 30px 30px;
}
.lesson_sidebar .sidebar_tools .go_back {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  font-size: 15px;
  line-height: 15px;
  opacity: 0.7;
}
.lesson_sidebar .sidebar_tools .go_back a {
  color: #040E26;
}
.lesson_sidebar .sidebar_tools .go_back svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.lesson_sidebar .lessons_list {
  padding-bottom: 200px;
}
.lesson_sidebar .lessons_list .go_back {
  display: none;
}
.lesson_sidebar .lessons_list a {
  position: relative;
  display: block;
  padding: 22px 70px 17px 60px;
  font-size: 17px;
  line-height: 25px;
  font-weight: 400;
  color: unset;
}
.lesson_sidebar .lessons_list a.current {
  background-color: rgba(4, 14, 38, 0.0235294118);
}
.lesson_sidebar .lessons_list a.current:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background-color: #040E26;
}
.lesson_sidebar .lessons_list a .date_start {
  display: block;
  padding-top: 10px;
  font-size: 15px;
  line-height: 20px;
  opacity: 0.7;
}
.lesson_sidebar .lessons_list a .type_icon {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 20px;
  left: 30px;
}
.lesson_sidebar .lessons_list a .type_icon svg {
  width: 100%;
}
.lesson_sidebar .lessons_list a .status_icon_border {
  border: 1px solid #E6E7E9;
  border-radius: 20px;
}
.lesson_sidebar .lessons_list a .status_icon {
  position: absolute;
  width: 21px;
  height: 21px;
  top: 20px;
  right: 30px;
}
.lesson_sidebar .lessons_list a .status_icon svg {
  width: 100%;
}
@media screen and (max-width: 600px) {
  .lesson_sidebar {
    width: 100%;
  }
}