.edit_create_wrapper {
  position: relative;
  max-width: 1088px;
  margin: 0 auto;
  padding-top: 90px;
  padding-bottom: 120px;
  text-align: center;
}
.edit_create_wrapper .cc_top h2 {
  font-size: 24px;
  line-height: 32px;
}
.edit_create_wrapper .courses_block {
  text-align: center;
  display: block;
}
.edit_create_wrapper .courses_block .course_preview {
  text-align: left;
  display: inline-block;
}
.edit_create_wrapper .preloader_wrapper {
  z-index: 5;
}
.edit_create_wrapper h6 {
  height: 24px;
  line-height: 24px;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.edit_create_wrapper .course_form_hint {
  font-size: 10px;
  line-height: 16px;
  color: #C0C2C8;
}
.edit_create_wrapper .course_form_hint a {
  text-decoration: none;
  color: #C0C2C8;
  border-bottom: 1px solid #C0C2C8;
}
.edit_create_wrapper .course_form_hint.cfh_abs {
  position: absolute;
}
.edit_create_wrapper .course_name_block {
  margin-bottom: 42px;
  text-align: center;
}
.edit_create_wrapper .course_name_block .course_name_input {
  width: 100%;
  padding: 0;
  border: 0 solid;
  border-bottom: 2px solid #040E26;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  min-height: 72px;
  color: #434A5C;
}
.edit_create_wrapper .course_name_block .course_name_input.placeholder {
  color: #C0C2C8;
}
.edit_create_wrapper .course_name_block .course_name_input:-moz-placeholder {
  color: #C0C2C8;
}
.edit_create_wrapper .course_name_block .course_name_input::-moz-placeholder {
  color: #C0C2C8;
}
.edit_create_wrapper .course_name_block .course_name_input:-ms-input-placeholder {
  color: #C0C2C8;
}
.edit_create_wrapper .course_name_block .course_name_input::-webkit-input-placeholder {
  color: #C0C2C8;
}
.edit_create_wrapper .course_name_block .course_name_input:disabled {
  border: 0 solid;
  background-color: transparent;
}
.edit_create_wrapper .course_name_block .course_form_hint {
  display: inline-block;
  width: 640px;
  padding-top: 12px;
}
.edit_create_wrapper .course_edit_block {
  text-align: left;
}
.edit_create_wrapper .course_edit_block h5 {
  font-size: 10px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #C0C2C8;
}
.edit_create_wrapper .course_edit_block h5.pseudo_link {
  display: inline-block;
  border-bottom: 1px dashed rgba(146, 129, 129, 0.5);
  cursor: pointer;
}
.edit_create_wrapper .course_edit_block .ce_grid {
  display: flex;
}
.edit_create_wrapper .course_edit_block .ce_grid .ce_grid_block {
  width: 100%;
}
.edit_create_wrapper .course_edit_block .ce_grid .ce_grid_block.ce_left_block {
  width: 303px;
  margin-right: 33px;
  flex-shrink: 0;
}
.edit_create_wrapper .course_edit_block .course_form_hint {
  padding-top: 8px;
}
.edit_create_wrapper .course_edit_block .ce_button_holder {
  text-align: center;
  padding-top: 64px;
}/*# sourceMappingURL=index.css.map */