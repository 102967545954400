.pdf_viewer .pdf_player .pdf_document_holder {
  overflow: hidden;
  margin-bottom: 16px;
}
.pdf_viewer .pdf_player .pdf_document_holder .react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}
.pdf_viewer .pdf_player .pdf_controls {
  display: flex;
  justify-content: space-between;
}
.pdf_viewer .pdf_player .pdf_controls .pci_btn_holder {
  width: 24px;
  flex-shrink: 0;
}
.pdf_viewer .pdf_player .pdf_controls .pci_btn_holder .pci_btn {
  position: relative;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.pdf_viewer .pdf_player .pdf_controls .pci_btn_holder .pci_btn i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
}
.pdf_viewer .pdf_player .pdf_controls .pci_btn_holder .pci_btn i svg {
  width: 16px;
  height: 16px;
}
.pdf_viewer .pdf_player .pdf_controls .pci_btn_holder .pci_btn.pci_btn_next i svg {
  transform: rotate(180deg);
}/*# sourceMappingURL=index.css.map */