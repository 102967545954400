.profile_date_picker_wrapper {
  position: relative;
}
.profile_date_picker_wrapper input[type=date]::-webkit-inner-spin-button {
  display: none;
}
.profile_date_picker_wrapper input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
}
.profile_date_picker_wrapper input[type=date] {
  cursor: text;
  outline: none;
  border: none;
}
.profile_date_picker_wrapper_modal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px 8px 12px;
  box-sizing: border-box;
  width: 310px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.profile_date_picker_wrapper_profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 360px;
  padding: 8px 12px 8px 20px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
  margin-right: 24px;
  margin-left: 140px;
  height: 40px;
  box-sizing: border-box;
}
@media screen and (max-width: 480px) {
  .profile_date_picker_wrapper_profile {
    margin-left: 0px;
    width: 320px;
  }
}

.profile_date_picker {
  z-index: 5;
  position: absolute;
  right: 25px;
  top: 45px;
  width: 264px;
  min-height: 336px;
  padding: 20px;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.profile_date_picker_icon {
  cursor: pointer;
  width: 16px;
  height: 18px;
  background-size: cover;
  background-repeat: no-repeat;
}
@media screen and (max-width: 480px) {
  .profile_date_picker_icon {
    width: 21px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.profile_date_picker_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.profile_date_picker_header_date {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2450FF;
}
.profile_date_picker_header_date select {
  font-family: "Inter", sans-serif;
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  margin-right: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #2450FF;
}
.profile_date_picker_header_nav {
  display: flex;
  flex-direction: row;
  gap: 22px;
}
.profile_date_picker_header_nav_arrow_left {
  cursor: pointer;
  rotate: 180deg;
  width: 12px;
  height: 14px;
  background-repeat: no-repeat;
  background-size: contain;
}
.profile_date_picker_header_nav_arrow_right {
  cursor: pointer;
  width: 12px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
}
.profile_date_picker_body {
  min-height: 220px;
}
.profile_date_picker_body_days {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 4px;
}
.profile_date_picker_body_days_week {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #121212;
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.profile_date_picker_body_days_week span {
  width: 28px;
}
.profile_date_picker_body_days_weekend {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #96A3AE;
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.profile_date_picker_body_days_weekend span {
  width: 28px;
}
.profile_date_picker_body_table_row {
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-bottom: 7px;
}
.profile_date_picker_body_table_row_selected {
  border-radius: 8px;
  background: #2450FF !important;
  color: #FFFFFF !important;
}
.profile_date_picker_body_table_row_current_day {
  font-weight: 600 !important;
  color: #2450FF !important;
}
.profile_date_picker_body_table_row span {
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 28px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #96A3AE;
}
.profile_date_picker_footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.profile_date_picker_footer_cancel {
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-right: 48px;
  text-align: center;
  color: #2450FF;
}
.profile_date_picker_footer_save {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95px;
  height: 32px;
  color: #FFFFFF;
  background: #2450FF;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}/*# sourceMappingURL=ProfileDatePickerCalendar.css.map */