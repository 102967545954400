.cl_authors {
  padding: 68px 0 72px;
}
.cl_authors .authors_carousel {
  position: relative;
}
.cl_authors .authors_carousel .feed_holder {
  overflow: hidden;
  text-align: left;
}
.cl_authors .authors_carousel .feed_holder .feed {
  display: flex;
  flex-wrap: nowrap;
  transition: margin-left ease-out 300ms;
}
.cl_authors .authors_carousel .feed_holder .feed .feed_item {
  width: 308px;
  flex-shrink: 0;
  margin-right: 30px;
  padding: 52px 30px 60px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(4, 14, 38, 0.062745098);
}
.cl_authors .authors_carousel .feed_holder .feed .feed_item_header {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}
.cl_authors .authors_carousel .feed_holder .feed .feed_item_header .userpic {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
}
.cl_authors .authors_carousel .feed_holder .feed .feed_item_header .userpic img, .cl_authors .authors_carousel .feed_holder .feed .feed_item_header .userpic span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cl_authors .authors_carousel .feed_holder .feed .feed_item_header .userpic span {
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  font-weight: 500;
}
.cl_authors .authors_carousel .feed_holder .feed .feed_item_header_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  margin-left: 16px;
}
.cl_authors .authors_carousel .feed_holder .feed .feed_item_header_title_prof {
  font-size: 15px;
  color: #040E26;
  opacity: 0.7;
}
.cl_authors .authors_carousel .feed_holder .feed .feed_item_header_title h5 {
  position: relative;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
}
.cl_authors .authors_carousel .feed_holder .feed .feed_item .description {
  font-size: 17px;
  line-height: 25px;
}
.cl_authors .authors_carousel .arr {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 20px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.7;
}
.cl_authors .authors_carousel .arr:hover {
  opacity: 1;
}
.cl_authors .authors_carousel .arr:before {
  content: "";
  position: absolute;
  top: -6px;
  width: 30px;
  height: 30px;
  border: 1px solid #040E26;
  transform: rotate(45deg);
}
.cl_authors .authors_carousel .arr.arr_left {
  right: 100%;
  margin-right: 30px;
}
.cl_authors .authors_carousel .arr.arr_left:before {
  left: 7px;
}
.cl_authors .authors_carousel .arr.arr_right {
  left: 100%;
  margin-left: 30px;
}
.cl_authors .authors_carousel .arr.arr_right:before {
  right: 7px;
}
@media screen and (max-width: 700px) {
  .cl_authors {
    padding: 41px 0;
  }
  .cl_authors .cl_centrer {
    margin: 0;
  }
  .cl_authors .cl_centrer h2 {
    margin: 0 20px 35px;
  }
  .cl_authors .authors_carousel .feed_holder .feed .feed_item {
    width: 248px;
    padding: 36px 20px 30px;
    margin-right: 20px;
  }
  .cl_authors .authors_carousel .feed_holder .feed .feed_item:first-of-type {
    margin-left: 20px;
  }
  .cl_authors .authors_carousel .feed_holder .feed .feed_item h5 {
    font-size: 17px;
    line-height: 25px;
  }
  .cl_authors .authors_carousel .feed_holder .feed .feed_item h5 .userpic {
    width: 60px;
    height: 60px;
  }
  .cl_authors .arr {
    display: none;
  }
}