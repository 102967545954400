.cl_leave_review {
  background-color: #F5F5F5;
  padding: 80px 0;
}
.cl_leave_review .cl_centrer {
  display: flex;
}
.cl_leave_review .cl_centrer .cl_left {
  width: calc(50% - 130px);
  padding-right: 130px;
}
.cl_leave_review .cl_centrer .cl_left h2 {
  margin-top: -12px;
  margin-bottom: 39px;
}
.cl_leave_review .cl_centrer .cl_left p {
  font-size: 22px;
  line-height: 30px;
  opacity: 0.5;
}
.cl_leave_review .cl_centrer .cl_right {
  position: relative;
  width: 50%;
  background-color: #fff;
  border-radius: 10px;
}
.cl_leave_review .leave_review_block {
  padding: 58px 60px;
}
.cl_leave_review .leave_review_block.rated .score, .cl_leave_review .leave_review_block.rated textarea, .cl_leave_review .leave_review_block.rated button {
  display: none;
}
.cl_leave_review .leave_review_block.rated .rated_message {
  display: block;
}
.cl_leave_review .leave_review_block .score {
  margin-bottom: 30px;
}
.cl_leave_review .leave_review_block .score label {
  display: block;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.7;
  margin-bottom: 18px;
}
.cl_leave_review .leave_review_block .score ul {
  display: flex;
  align-content: flex-start;
}
.cl_leave_review .leave_review_block .score ul li {
  width: 32px;
  height: 30px;
  padding-right: 5px;
  flex-shrink: 0;
  cursor: pointer;
}
.cl_leave_review .leave_review_block .score ul li.selected svg path {
  fill: #FFD500;
}
.cl_leave_review .leave_review_block textarea {
  width: calc(100% - 26px);
  height: 75px;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid rgba(4, 14, 38, 0.062745098);
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 48px;
}
.cl_leave_review .leave_review_block button {
  width: 100%;
}
.cl_leave_review .leave_review_block .rated_message {
  display: none;
  font-size: 17px;
  line-height: 25px;
}
.cl_leave_review .leave_review_block .rated_message strong {
  font-weight: 500;
}
@media screen and (max-width: 1180px) {
  .cl_leave_review .cl_centrer {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .cl_leave_review .cl_centrer .cl_left {
    width: 100%;
    margin-right: 0;
    padding-right: 0;
    margin-bottom: 39px;
  }
  .cl_leave_review .cl_centrer .cl_right {
    min-width: 600px;
  }
}
@media screen and (max-width: 700px) {
  .cl_leave_review {
    padding: 41px 0 50px;
  }
  .cl_leave_review .cl_centrer .cl_left h2 {
    margin-bottom: 31px;
  }
  .cl_leave_review .cl_centrer .cl_left p {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 37px;
  }
  .cl_leave_review .cl_centrer .cl_right {
    min-width: 0;
    width: 100%;
  }
  .cl_leave_review .cl_centrer .cl_right .leave_review_block {
    padding: 30px 20px 48px;
  }
}/*# sourceMappingURL=index.css.map */