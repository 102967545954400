.cl_about_school {
  padding: 68px 0 120px;
  background-color: #F5F5F5;
}
.cl_about_school .description_wrapper {
  position: relative;
  display: flex;
  align-items: flex-start;
}
.cl_about_school .description_wrapper .description {
  flex-shrink: 0;
  width: calc(50% - 130px);
  margin-right: 130px;
  font-size: 17px;
  line-height: 25px;
}
.cl_about_school .description_wrapper .school_card {
  margin-top: -20px;
  width: 50%;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: #fff;
}
.cl_about_school .description_wrapper .school_card .card_inner {
  position: relative;
  padding: 72px 100px 72px 170px;
}
.cl_about_school .description_wrapper .school_card .card_inner .subscribe_to_school {
  position: absolute;
  top: 0;
  right: 30px;
  cursor: pointer;
}
.cl_about_school .description_wrapper .school_card .card_inner .userpic {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 60px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #040E26;
}
.cl_about_school .description_wrapper .school_card .card_inner .userpic img, .cl_about_school .description_wrapper .school_card .card_inner .userpic span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cl_about_school .description_wrapper .school_card .card_inner .userpic img {
  width: 100%;
}
.cl_about_school .description_wrapper .school_card .card_inner .userpic span {
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  font-weight: 500;
}
.cl_about_school .description_wrapper .school_card .card_inner .title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  margin-bottom: 14px;
}
.cl_about_school .description_wrapper .school_card .card_inner .title a {
  text-decoration: none;
  color: unset;
}
.cl_about_school .description_wrapper .school_card .card_inner .courses_count {
  font-size: 15px;
  line-height: 15px;
}
.cl_about_school .description_wrapper .school_card .card_inner .courses_count a {
  color: #2450FF;
  text-decoration: none;
  border-bottom: 1px solid rgba(36, 80, 255, 0.3137254902);
}
@media screen and (max-width: 900px) {
  .cl_about_school .description_wrapper {
    flex-wrap: wrap-reverse;
  }
  .cl_about_school .description_wrapper .description {
    width: 100%;
    margin-right: 0;
  }
  .cl_about_school .description_wrapper .school_card {
    width: 100%;
    margin-top: 0;
    margin-bottom: 62px;
  }
}
@media screen and (max-width: 700px) {
  .cl_about_school {
    padding: 41px 0 50px;
  }
  .cl_about_school .cl_centrer h2 {
    margin-bottom: 36px;
  }
  .cl_about_school .description_wrapper .school_card {
    margin-bottom: 36px;
  }
  .cl_about_school .description_wrapper .school_card .card_inner {
    padding: 65px 50px 50px 100px;
  }
  .cl_about_school .description_wrapper .school_card .card_inner .userpic {
    width: 60px;
    height: 60px;
    left: 20px;
  }
  .cl_about_school .description_wrapper .school_card .card_inner .title {
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 2px;
  }
}/*# sourceMappingURL=index.css.map */