.profile_nav::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}

.profile_nav::-webkit-scrollbar {
  display: block;
  width: 14px;
  background-color: none;
}

.profile_nav::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #EAEDEF;
}

.profile_wrapper {
  position: relative;
  padding: 86px 0 0 255px;
}
.profile_wrapper .profile_locked_page {
  font-size: 40px;
  font-weight: 600;
}
@media screen and (max-width: 480px) {
  .profile_wrapper .profile_development_link {
    margin-top: 80px;
  }
}
@media screen and (max-width: 820px) and (min-width: 481px) {
  .profile_wrapper .mobile_open {
    transition: 0.5s;
    left: 0px !important;
  }
}
@media screen and (max-width: 480px) {
  .profile_wrapper .mobile_open {
    visibility: visible !important;
    opacity: 1 !important;
  }
}
.profile_wrapper .mobile_switcher {
  display: none;
}
@media screen and (max-width: 820px) {
  .profile_wrapper .mobile_switcher {
    top: 0;
    display: block;
    width: 60px;
    height: 59px;
    position: fixed;
    z-index: 9999;
  }
}
@media screen and (max-width: 480px) {
  .profile_wrapper {
    padding: 0px;
    overflow-x: hidden;
  }
}
@media screen and (max-width: 820px) {
  .profile_wrapper {
    margin-top: 80px;
    padding: 0px;
    overflow-x: hidden;
  }
}
body.no_dashboard .profile_wrapper {
  padding-left: 0;
  padding-top: 70px;
}
.profile_wrapper .profile_bg {
  height: 120px;
  background-color: #f3f4f8;
}
.profile_wrapper .profile_inner {
  margin: 0;
}
.profile_wrapper .profile_inner .profile_header {
  display: none;
}
.profile_wrapper .profile_nav_feed_userData {
  width: 60px;
  height: 200px;
}
.profile_wrapper .profile_nav {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 70px;
  height: calc(100vh - 70px);
  width: 227px;
  background-color: #FAFBFC;
  border-right: 1px solid #E8E8E8;
  overflow: auto;
  overflow-x: hidden;
}
@media screen and (max-width: 480px) {
  .profile_wrapper .profile_nav {
    z-index: 9999;
    top: 60px;
    max-height: 650px;
    overflow: hidden;
    justify-content: center;
    width: 100%;
  }
}
@media screen and (min-height: 800px) {
  .profile_wrapper .profile_nav_footer {
    position: absolute;
    bottom: 140px !important;
  }
}
.profile_wrapper .profile_nav .profile_nav_feed {
  white-space: nowrap;
  font-size: 0;
  padding-top: 16px;
  padding: 0px 10px 50px 0px;
}
.profile_wrapper .profile_nav .img_link {
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #1F57C3;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  height: 30px;
  padding: 6px 18px;
}
@media screen and (max-width: 480px) {
  .profile_wrapper .profile_nav .img_link {
    width: 298px;
    font-size: 14px;
  }
}
.profile_wrapper .profile_nav .img_link:hover {
  background-color: rgba(31, 87, 195, 0.1);
  border-radius: 3px;
}
.profile_wrapper .profile_nav .img_link img {
  height: 14px;
  width: 16px;
  margin-right: 6px;
}
.profile_wrapper .profile_nav .notifications_count {
  position: absolute;
  right: 10px;
  width: 30px;
  height: 19px;
  border-radius: 16px;
  background-color: #E933C3;
  color: white;
  text-align: center;
  line-height: 19px;
}
.profile_wrapper .profile_nav .profile_user_data {
  width: 100%;
  display: flex;
  margin-bottom: 37px;
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 17px;
}
@media screen and (max-width: 480px) {
  .profile_wrapper .profile_nav .profile_user_data {
    flex-direction: row;
  }
}
.profile_wrapper .profile_nav .profile_user_data_divider {
  margin-top: 24px;
  margin-bottom: 24px;
}
@media screen and (max-width: 480px) {
  .profile_wrapper .profile_nav .profile_user_data_avatar_outer {
    margin-right: 20px;
  }
}
.profile_wrapper .profile_nav .profile_user_data_avatar_inner {
  width: 60px;
  height: 60px;
  background-color: #F2F0F0;
  border-radius: 320px;
  border: solid white 2px;
  text-transform: uppercase;
  font-size: 36px;
  text-align: center;
  line-height: 60px;
  vertical-align: center;
  cursor: default;
}
@media screen and (max-width: 480px) {
  .profile_wrapper .profile_nav .profile_user_data_avatar_inner {
    width: 84px;
    height: 84px;
  }
}
.profile_wrapper .profile_nav .profile_user_data_name {
  width: 100%;
  display: flex;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
}
.profile_wrapper .profile_nav .profile_user_data_name_main {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
}
.profile_wrapper .profile_nav .profile_user_data_name_display {
  white-space: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #1F57C3;
  margin-bottom: 2px;
}
.profile_wrapper .profile_nav .profile_user_data_name_email {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #A9A9A9;
}
.profile_wrapper .profile_nav a {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 19px;
  white-space: normal;
  color: #C0C2C8;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}
.profile_wrapper .profile_nav a sup {
  position: absolute;
  left: 100%;
  margin-left: 3px;
  top: -1px;
  font-size: 14px;
  line-height: 14px;
  color: #46C790;
}
.profile_wrapper .profile_nav a:hover {
  background-color: rgba(31, 87, 195, 0.1);
  border-radius: 3px;
}
.profile_wrapper .profile_nav a.active {
  color: #434A5C;
  background-color: rgba(31, 87, 195, 0.1);
  border-radius: 3px;
}
.profile_wrapper .profile_nav a.red {
  color: #C33333;
}
.profile_wrapper .profile_nav a.red.active {
  border-bottom-color: #C33333;
}
@media screen and (max-width: 820px) and (max-width: 820px) and (min-width: 481px) {
  .profile_wrapper .profile_nav {
    left: -227px;
    -webkit-animation: slide 0.5s forwards;
    -webkit-animation-delay: 2s;
    animation: slide 0.3s forwards;
    animation-delay: 1s;
  }
}
@media screen and (max-width: 820px) and (max-width: 480px) {
  .profile_wrapper .profile_nav {
    visibility: hidden;
    opacity: 0;
    transition: opacity 400ms, visibility 400ms;
  }
}
@media screen and (max-width: 820px) {
  .profile_wrapper .profile_inner {
    margin: 0 24px;
  }
  .profile_wrapper .profile_inner .profile_header {
    display: block;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    padding-top: 24px;
  }
}
@keyframes slide-from-left {
  0% {
    left: -227px;
  }
  100% {
    left: 0;
  }
}