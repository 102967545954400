.ls_pre_footer_wrapper {
  padding: 100px 0 147px;
}
.ls_pre_footer_wrapper .items {
  white-space: nowrap;
  font-size: 0;
}
.ls_pre_footer_wrapper .items li {
  display: inline-block;
  vertical-align: top;
  width: 250px;
  margin-right: 150px;
  white-space: normal;
}
.ls_pre_footer_wrapper .items li:last-of-type {
  margin-right: 0;
}
.ls_pre_footer_wrapper .items li img {
  margin-bottom: 44px;
}
.ls_pre_footer_wrapper .items li h2 {
  font-size: 45px;
  line-height: 55px;
  font-weight: 500;
  margin-bottom: 26px;
}
.ls_pre_footer_wrapper .items li p {
  font-size: 14px;
  line-height: 25px;
  color: #444750;
}
.ls_pre_footer_wrapper .items li p a {
  text-decoration: none;
  color: #2450FF;
  border-bottom: 1px solid rgba(36, 80, 255, 0.3137254902);
}
@media screen and (max-width: 1440px) {
  .ls_pre_footer_wrapper .items li {
    width: auto;
    max-width: 230px;
    margin-right: 132px;
  }
  .ls_pre_footer_wrapper .items li:last-of-type {
    margin-right: 0;
  }
}
@media screen and (max-width: 1050px) {
  .ls_pre_footer_wrapper {
    padding-bottom: 80px;
  }
  .ls_pre_footer_wrapper .items {
    white-space: normal;
  }
  .ls_pre_footer_wrapper .items li {
    display: block;
    text-align: center;
    max-width: 1000px;
    margin-right: 0;
    margin-bottom: 57px;
  }
  .ls_pre_footer_wrapper .items li img {
    display: inline-block;
    margin-bottom: 26px;
  }
  .ls_pre_footer_wrapper .items li h2 {
    margin-bottom: 13px;
    font-size: 30px;
    line-height: 35px;
  }
  .ls_pre_footer_wrapper .items li:last-of-type {
    margin-right: 0;
  }
}/*# sourceMappingURL=index.css.map */