.experts_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  gap: 20px;
}
.experts_modal_row {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
.experts_modal_column {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.experts_modal_column .userpic_upload_wrapper {
  margin-bottom: 0px !important;
}
.experts_modal_column:first-of-type {
  justify-content: flex-end;
  gap: 25px;
}
.experts_modal_tip {
  cursor: pointer;
  width: 18px;
  height: 18px;
  background: lightgray;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: #1F57C3;
}
.experts_modal_tip_content {
  position: absolute;
  left: 80px;
  width: 250px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
}
.experts_modal_title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1F57C3;
}
.experts_modal_upload_wrapper {
  align-self: flex-start;
  padding: 15px;
  align-self: center;
  margin-right: 200px;
}
.experts_modal_textarea {
  height: 120px !important;
}
.experts_modal_input.validate_error {
  border: 1px solid rgba(221, 29, 29, 0.664);
}
.experts_modal_input {
  display: flex;
  align-items: center;
  width: 300px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.experts_modal_input_label {
  position: relative;
  display: flex;
  gap: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #96A3AE;
  margin-bottom: 8px;
}
.experts_modal_input input {
  width: 240px;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  margin-left: 10px;
  font-size: 16px;
  line-height: 40px;
  color: #1F57C3;
}
.experts_modal_input textarea {
  width: 280px;
  height: 110px;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  margin-left: 10px;
  font-size: 16px;
  line-height: 30px;
  color: #1F57C3;
}
.experts_modal_button_block {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}
.experts_modal_button_block_decline {
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.experts_modal_button_block_confirm {
  cursor: pointer;
  box-sizing: border-box;
  height: 29px;
  width: 107px;
  padding: 7px 22px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  background: #1F57C3;
  border-radius: 50px;
}/*# sourceMappingURL=expertsModal.css.map */