.delete_modal_info {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1F57C3;
  margin-bottom: 25px;
}
.delete_modal_btn {
  cursor: pointer;
  width: 285px;
  height: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1F57C3;
  border-radius: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #FFFFFF;
}

.password_modal {
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.password_modal_error {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #F02D2D;
}
.password_modal_title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1F57C3;
  margin-bottom: 17px;
}
.password_modal .old_password {
  margin-bottom: 30px;
}
.password_modal_input {
  width: 285px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  margin-bottom: 14px;
  border-radius: 4px;
  box-sizing: border-box;
}
.password_modal_input_label {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #1F57C3;
}
.password_modal_input input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  font-size: 16px;
  line-height: 19px;
  padding: 10px 10px;
  color: #1F57C3;
}
.password_modal_button_block {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}
.password_modal_button_block_decline {
  cursor: pointer;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.password_modal_button_block_confirm {
  cursor: pointer;
  box-sizing: border-box;
  height: 29px;
  width: 107px;
  padding: 7px 22px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  background: #1F57C3;
  border-radius: 50px;
}

.profile_modal {
  box-sizing: border-box;
  position: fixed;
  min-width: 300px;
  min-height: 200px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  z-index: 999;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  max-height: 85vh;
  overflow: hidden;
}
@media screen and (max-width: 480px) {
  .profile_modal {
    max-height: 85vh;
    overflow: hidden;
  }
}
.profile_modal_head {
  height: 20px;
  width: 100%;
}
.profile_modal_title {
  margin-bottom: 10px;
}
.profile_modal_close {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 13px;
  height: 20px;
  text-align: center;
  line-height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.snils_modal {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
}
.snils_modal_title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1F57C3;
}
.snils_modal_input {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 310px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.snils_modal_input input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  font-size: 16px;
  line-height: 40px;
  margin-left: 10px;
  color: #1F57C3;
}
.snils_modal_save_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  width: 107px;
  height: 29px;
  background: #1F57C3;
  border-radius: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.passport_modal {
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 20px;
}
.passport_modal_title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1F57C3;
}
.passport_modal_attach.validate_error {
  border: 1px solid rgba(221, 29, 29, 0.664);
}
.passport_modal_attach {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 310px;
  height: 40px;
  background: rgba(150, 163, 174, 0.2);
  border-radius: 4px;
  padding: 11px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #96A3AE;
}
.passport_modal_attach_desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.passport_modal_input.validate_error {
  border: 1px solid rgba(221, 29, 29, 0.664);
}
.passport_modal_input {
  display: flex;
  align-items: center;
  width: 310px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.passport_modal_input input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  width: 270px;
  font-size: 16px;
  line-height: 40px;
  margin-left: 10px;
  color: #1F57C3;
}
.passport_modal_input input[type=date] {
  width: 290px;
}
.passport_modal_input_label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
  color: #1F57C3;
}
.passport_modal_save_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  width: 107px;
  height: 29px;
  background: #1F57C3;
  border-radius: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.diploma_modal::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}

.diploma_modal::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 65px;
}

.diploma_modal::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 75px;
}

.diploma_modal::-webkit-scrollbar {
  display: block;
  width: 14px;
  background-color: none;
}

.diploma_modal::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #EAEDEF;
}

.diploma_modal {
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;
  height: 557px;
  padding: 0px 30px 30px 30px;
  gap: 20px;
}
.diploma_modal_title {
  align-self: start;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1F57C3;
}
.diploma_modal_attach.validate_error {
  border: 1px solid rgba(221, 29, 29, 0.664);
}
.diploma_modal_attach {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 310px;
  height: 40px;
  background: rgba(150, 163, 174, 0.2);
  border-radius: 4px;
  padding: 11px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #96A3AE;
}
.diploma_modal_attach_desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.diploma_modal_input.validate_error {
  border: 1px solid rgba(221, 29, 29, 0.664);
}
.diploma_modal_input {
  position: relative;
  display: flex;
  align-items: center;
  width: 310px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.diploma_modal_input_arrow {
  cursor: pointer;
  position: absolute;
  right: 10px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  rotate: 90deg;
  background-position: center;
}
.diploma_modal_input select {
  z-index: 400;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  padding-right: 30px;
  box-sizing: border-box;
  width: 290px;
  font-size: 16px;
  line-height: 40px;
  margin-left: 10px;
  color: #1F57C3;
}
.diploma_modal_input input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  width: 270px;
  font-size: 16px;
  line-height: 40px;
  margin-left: 10px;
  color: #1F57C3;
}
.diploma_modal_input input[type=date] {
  width: 290px;
}
.diploma_modal_input_label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
  color: #1F57C3;
}
.diploma_modal_radio_group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
.diploma_modal_radio_group_item {
  display: flex;
  align-items: center;
  gap: 10px;
}
.diploma_modal_radio_group_radio {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.diploma_modal_radio_group_radio_label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #1F57C3;
}
.diploma_modal_radio_group_radio_selected {
  width: 12px;
  height: 12px;
  background: #1F57C3;
  border-radius: 12px;
  position: absolute;
  left: 6px;
}
.diploma_modal_radio_group_radio input[type=radio] {
  cursor: pointer;
  background: #FFFFFF;
  border: 2px solid rgba(150, 163, 174, 0.2);
  border-radius: 20px;
  width: 20px;
  height: 20px;
}
.diploma_modal_changeName_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.diploma_modal_changeName_form_registration {
  height: 80px !important;
  display: initial !important;
  padding-top: 5px;
  box-sizing: border-box;
}
.diploma_modal_changeName_form_registration textarea {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  height: 80px;
  width: 290px;
  box-sizing: border-box;
  font-size: 16px;
  margin-left: 10px;
  color: #1F57C3;
}
.diploma_modal_changeName_form_registration textarea::-moz-placeholder {
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.diploma_modal_changeName_form_registration textarea::placeholder {
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.diploma_modal_changeName_form_input {
  display: flex;
  align-items: center;
  width: 310px;
  height: 40px;
  line-height: 40px;
  background-color: #FFFFFF;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.diploma_modal_changeName_form_input input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  width: 270px;
  font-size: 16px;
  line-height: 40px;
  margin-left: 10px;
  color: #1F57C3;
}
.diploma_modal_changeName_form_input input[type=date] {
  width: 290px;
}
.diploma_modal_changeName_form_input input::-moz-placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.diploma_modal_changeName_form_input input::placeholder {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.diploma_modal_changeName_form_attach {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 310px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
  padding: 11px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #96A3AE;
}
.diploma_modal_changeName_form_attach_desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.diploma_modal_save_btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  width: 107px;
  height: 29px;
  background: #1F57C3;
  border-radius: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}

.address_modal::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: none;
}

.address_modal::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 65px;
}

.address_modal::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 75px;
}

.address_modal::-webkit-scrollbar {
  display: block;
  width: 14px;
  background-color: none;
}

.address_modal::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 10px;
  background-color: #EAEDEF;
}

.address_modal {
  display: flex;
  height: 557px;
  overflow: auto;
  flex-direction: column;
  padding: 0px 30px 30px 30px;
  gap: 20px;
}
.address_modal_row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.address_modal_title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1F57C3;
}
.address_modal_attach {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 310px;
  height: 40px;
  background: rgba(150, 163, 174, 0.2);
  border-radius: 4px;
  padding: 11px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #96A3AE;
}
.address_modal_attach_desc {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #96A3AE;
}
.address_modal_input {
  display: flex;
  align-items: center;
  width: 310px;
  height: 40px;
  border: 1px solid rgba(150, 163, 174, 0.2);
  border-radius: 4px;
}
.address_modal_input input {
  outline: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  background: none;
  width: 270px;
  font-size: 16px;
  line-height: 40px;
  margin-left: 10px;
  color: #1F57C3;
}
.address_modal_input input[type=date] {
  width: 290px;
}
.address_modal_input input:disabled {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #96A3AE;
}
.address_modal_input_label {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 10px;
  color: #1F57C3;
}
.address_modal_suggest {
  position: relative;
  width: 310px;
  display: flex;
  flex-direction: column;
  position: absolute;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.address_modal_suggest_loader {
  align-self: center;
  margin: 10px;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  border-bottom: rgba(150, 163, 174, 0.6) 2px solid;
  border-right: rgba(150, 163, 174, 0.6) 2px solid;
  animation: loader 1s linear infinite;
}
.address_modal_suggest span {
  cursor: pointer;
  font-size: 16px;
  line-height: 40px;
  padding-left: 10px;
  color: #1F57C3;
}
.address_modal_suggest span:hover {
  background-color: #96A3AE;
}
.address_modal_save_btn {
  float: right;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: end;
  width: 107px;
  height: 29px;
  background: #1F57C3;
  border-radius: 50px;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}
@keyframes loader {
  100% {
    transform: rotate(360deg);
  }
}/*# sourceMappingURL=index.css.map */