.cl_purchase {
  margin-bottom: 32px;
  padding: 80px 0;
  background-color: #2450FF;
}
.cl_purchase .purchase_block {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.cl_purchase .purchase_block .part {
  position: relative;
  width: calc(50% - 120px);
  padding: 30px 60px 100px;
}
.cl_purchase .purchase_block .part_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}
.cl_purchase .purchase_block .part_header_type {
  display: flex;
  align-items: center;
  font-size: 15px;
  gap: 5px;
}
.cl_purchase .purchase_block .part_header_timestart span {
  font-weight: 600;
}
.cl_purchase .purchase_block .part_title {
  margin-top: 32px;
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  color: #040E26;
}
.cl_purchase .purchase_block .part .materials_block {
  position: relative;
  margin-top: 45px;
}
.cl_purchase .purchase_block .part .materials_block_title {
  margin-bottom: 12px;
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  color: #040E26;
}
.cl_purchase .purchase_block .part .materials_block .material_types {
  margin-top: 16px;
}
.cl_purchase .purchase_block .part .materials_block .material_types ul li {
  display: inline-block;
  margin-right: 30px;
  font-size: 15px;
  line-height: 15px;
  margin-bottom: 20px;
}
.cl_purchase .purchase_block .part .materials_block .material_types ul li span {
  font-weight: 600;
  display: inline-block;
  padding: 5px 12px;
  font-size: 15px;
  margin-right: 10px;
  color: #fff;
  border-radius: 20px;
  background-color: #040E26;
}
.cl_purchase .purchase_block .part.right {
  background-color: #F5F5F5;
}
.cl_purchase .purchase_block .part.right .price_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 30px;
}
.cl_purchase .purchase_block .part.right .u_kassa {
  display: block;
  flex-shrink: 0;
  width: 173px;
  height: 60px;
  background-image: url("./images/u-kassa.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .cl_purchase .purchase_block .part.right .u_kassa {
    background-image: url("./images/u-kassa@2x.png");
    background-size: 173px 60px;
  }
}
.cl_purchase .purchase_block h2 {
  margin-bottom: 43px;
}
.cl_purchase .purchase_block .price_block {
  margin-bottom: 37px;
}
.cl_purchase .purchase_block .price_block .discount_text {
  font-size: 17px;
  line-height: 17px;
  opacity: 0.7;
  margin-bottom: 8px;
}
.cl_purchase .purchase_block .price_block .actual_price {
  font-size: 48px;
  line-height: 48px;
  font-weight: 600;
}
.cl_purchase .purchase_block .price_block .regular_price_block {
  margin-top: 39px;
}
.cl_purchase .purchase_block .price_block .regular_price_block p {
  font-size: 15px;
  line-height: 15px;
  opacity: 0.5;
  margin-bottom: 9px;
}
.cl_purchase .purchase_block .price_block .regular_price_block span {
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  text-decoration: line-through;
  opacity: 0.5;
}
.cl_purchase .purchase_block .labels_block {
  margin-top: 40px;
  width: calc(100% - 120px);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.cl_purchase .purchase_block .labels_block .u_kassa {
  display: block;
  flex-shrink: 0;
  margin-right: 60px;
  width: 173px;
  height: 60px;
  background-image: url("./images/u-kassa.png");
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .cl_purchase .purchase_block .labels_block .u_kassa {
    background-image: url("./images/u-kassa@2x.png");
    background-size: 173px 60px;
  }
}
.cl_purchase .purchase_block .labels_block .certificate {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: relative;
  padding-left: 55px;
}
.cl_purchase .purchase_block .labels_block .certificate svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 40px;
  height: 40px;
}
.cl_purchase .purchase_block .labels_block .certificate span {
  font-size: 13px;
  line-height: 20px;
}
.cl_purchase .purchase_block .additional_params {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.cl_purchase .purchase_block .additional_params .date_and_slots {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.cl_purchase .purchase_block .additional_params .date_and_slots p {
  font-size: 15px;
  line-height: 15px;
  margin-right: 30px;
}
.cl_purchase .purchase_block .additional_params .date_and_slots p:last-of-type {
  margin-right: 0;
}
.cl_purchase .purchase_block .additional_params .date_and_slots p span {
  font-weight: 600;
}
.cl_purchase .purchase_block .additional_params .age_restriction {
  display: inline-block;
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
  padding: 8px 15px 9px;
  border-radius: 20px;
  border: 1px solid #040E26;
}
.cl_purchase .purchase_block .buttons_holder {
  position: absolute;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  width: calc(100% - 120px);
}
.cl_purchase .purchase_block .buttons_holder button {
  width: 100%;
  margin-bottom: 20px;
}
.cl_purchase .purchase_block .buttons_holder button:last-of-type {
  margin-bottom: 0;
}
.cl_purchase .cl_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 1170px;
  margin: 55px auto auto;
  color: #FFFFFF;
  font-size: 15px;
}
.cl_purchase .cl_footer_left {
  display: flex;
  flex-direction: row;
  gap: 20px;
  order: 2;
}
.cl_purchase .cl_footer_left_ico {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;
}
.cl_purchase .cl_footer_right {
  display: flex;
  flex-direction: row;
  gap: 40px;
}
.cl_purchase .cl_footer_right_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
.cl_purchase .cl_footer_right_item_ico {
  height: 12px;
  width: 13px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media screen and (max-width: 960px) {
  .cl_purchase .cl_footer_left {
    display: flex;
    flex-direction: column;
    order: 0;
  }
  .cl_purchase .cl_footer_right {
    order: 1;
  }
}
@media screen and (max-width: 480px) {
  .cl_purchase .cl_footer {
    align-items: flex-start;
    margin: 50px 30px 0px !important;
  }
  .cl_purchase .cl_footer_right {
    gap: 20px;
  }
  .cl_purchase .cl_footer_right_desc {
    display: none;
  }
}
@media screen and (max-width: 1180px) {
  .cl_purchase .cl_footer {
    width: auto;
    margin: 50px 50px 0px;
  }
}
@media screen and (max-width: 700px) {
  .cl_purchase {
    padding: 20px 0;
  }
  .cl_purchase .purchase_block {
    flex-wrap: wrap;
  }
  .cl_purchase .purchase_block .part {
    width: 100%;
    padding: 40px 20px 33px;
  }
  .cl_purchase .purchase_block .labels_block {
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    position: relative;
    width: auto;
    flex-wrap: wrap;
  }
  .cl_purchase .purchase_block .labels_block .u_kassa {
    margin-bottom: 30px;
  }
  .cl_purchase .purchase_block .labels_block .certificate {
    width: 140px;
  }
  .cl_purchase .purchase_block .additional_params {
    margin-bottom: 37px;
  }
  .cl_purchase .purchase_block .buttons_holder {
    position: absolute;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    transform: translate(0, 0);
    position: relative;
    width: auto;
  }
}