.cl_statistics {
  position: relative;
  padding: 60px 0 30px;
}
.cl_statistics .cl_centrer h2 {
  margin-bottom: 32px;
}
.cl_statistics .cl_centrer p {
  margin-bottom: 32px;
}
.cl_statistics .stat_tabs span {
  display: inline-block;
  margin-right: 16px;
  cursor: pointer;
}
.cl_statistics .stat_tabs span.active {
  font-weight: 500;
  cursor: default;
}
.cl_statistics .apexcharts-toolbar {
  display: none;
}/*# sourceMappingURL=index.css.map */